var Extra = Extra || {}

Extra.CompareProductItem = {
    render: ({ Title, Id, Src, Srcset}) => {
        return `
            <div class="compare-product-item" data-id="${Id}">
                <a class="icon-close js-compare-clear"></a>
                <img src="${Src}" srcset="${Srcset}" alt="${Title}">
                <h2>${Title}</h2>
            </div>
        `
    }
}